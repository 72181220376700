import styled from "styled-components"
import * as variables from "../Variables"

export const FeelerIntroStyles = styled.section`
  padding: 70px var(--borderSpacing);
  color: #000;
  background-color: var(--background);
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;

  h1 {
    text-align: center;
    margin-bottom: calc(var(--borderSpacing) * 1.3);
  }

  /* Title color emphasize */
  h1 > span {
    color: ${variables.FEELER_COLOR};
  }

  .image {
    width: 100vw;
    left: calc(var(--borderSpacing) * -1);
  }

  .header__excerpt {
    color: #627c6e;
    text-align: center;
    padding: 0 !important;
  }

  .content__h2 {
    text-align: center;
  }

  /* Title Section */
  .title__section {
    margin-top: calc(var(--borderSpacing) * 2);
    margin-bottom: calc(var(--borderSpacing) * 2);

    .title {
      margin-bottom: calc(var(--borderSpacing) * 1.5);
      h1 {
        font-size: var(--h2);
      }
      p {
        color: ${variables.FEELER_COLOR};
      }
    }
  }

  /* Description Section */
  .desc__section {
    margin-top: calc(var(--borderSpacing) * 2);
    margin-bottom: calc(var(--borderSpacing) * 2);

    .desc__text {
      margin-top: calc(var(--borderSpacing) * 3.5);
      margin-bottom: calc(var(--borderSpacing) * 1.5);

      .desc__header {
        margin: 0 auto calc(var(--borderSpacing) * 3.5);

        @media (min-width: 1152px) {
          margin: 0 auto calc(var(--borderSpacing) * 0.7);
        }
      }

      p {
        padding: 10px;
        line-height: 28px;
      }
    }

    .desc1__text {
      p {
        padding: 40px;
      }
      .content__p {
        border-top: 1px solid ${variables.FEELER_COLOR};
      }
    }

    .desc1__image {
      object-fit: cover;
    }

    .desc2__text {
      h1 {
        text-align: center;
      }

      ul {
        border-top: 1px solid ${variables.FEELER_COLOR};
        /* border-bottom: 1px solid ${variables.FEELER_COLOR}; */
        list-style: none;
        text-align: center;
        padding: 10px;

        li {
          p {
            text-align: left;
          }
        }
      }
    }
    .desc3__text {
      p {
        padding: 20px;
      }
      .content__p {
        border-top: 1px solid ${variables.FEELER_COLOR};
      }
    }
  }

  /* Laptop, Desktop */

  @media (min-width: 1152px) {
    .image {
      width: 100%;
      left: initial;
    }

    .header__excerpt {
      font-size: var(--h2);
      font-weight: 700;
    }

    /* Title Section */
    .title__section {
      .title {
        h1 {
          font-size: calc(var(--h1) * 1.1);
        }
        p {
          font-size: calc(var(--h2) * 1.4);
          font-weight: 900;
        }
      }

      .img__container {
        display: flex;
        gap: 40px;
        padding: 30px;

        .title__image {
          border-radius: 30px;
        }
      }
    }

    /* Description Section */
    .desc__section {
      .content {
        filter: drop-shadow(0.4rem 0.4rem 0.45rem rgba(0, 0, 30, 0.5));
      }
      /* z-index for content__h2 */
      .z__index__fix {
        position: relative;
        z-index: 99;
      }
      .content1 {
        display: flex;

        .content__text {
          z-index: 1;
          transform: translateX(-10%);
          order: 2;
          color: #fff;

          .content__h2 {
            background: ${variables.FEELER_COLOR};
            padding: 20px;
            text-align: center;
            transform: skew(-15deg) translate(-5%, 70%);
          }
          .content__p {
            position: relative;
            background: var(--darkGrayBlue);
            padding: 40px;
            transform: skew(-15deg) translate(-5%, 20%);
            p {
              transform: skew(15deg);
              font-size: 16px;
            }
          }
        }

        .desc1__image {
          border-radius: 30px;
        }
      }

      .content2 {
        display: flex;
        .content__text {
          z-index: 1;
          color: #fff;
          .content__h2 {
            position: relative;
            background: ${variables.FEELER_COLOR};
            padding: 30px;
            text-align: center;
            transform: skew(5deg) translate(20%, 20%);
            z-index: 2;
          }
          .content__ul {
            background: var(--darkGrayBlue);
            padding: 40px;
            transform: skew(5deg) translate(20%, 0);
            p {
              transform: skew(-5deg);
              font-size: 16px;
            }
          }
        }

        .desc2__image {
          border-radius: 30px;
        }
      }

      .content3 {
        display: flex;
        .content__text {
          z-index: 1;
          color: #fff;
          order: 2;
          .content__h2 {
            position: relative;
            background: ${variables.FEELER_COLOR};
            padding: 30px;
            text-align: center;
            transform: skew(-15deg) translate(-5%, 50%);
            z-index: 2;
          }
          .content__p {
            background: var(--darkGrayBlue);
            padding: 40px;
            transform: skew(-15deg) translate(-5%, 20%);
            p {
              transform: skew(15deg);
              font-size: 16px;
            }
          }
        }
        .desc3__image {
          border-radius: 30px;
        }
      }
    }
  }
`
