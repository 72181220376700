import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Fade } from "react-awesome-reveal"
import { feelerProduction } from "../../constants/feelerintroData"
import PageLinks from "../PageLinks/PageLinks"
import { FeelerIntroStyles } from "./FeelerIntroStyles"

const FeelerIntro = () => {
  return (
    <>
      <FeelerIntroStyles>
        <div className="container">
          <Fade duration="2000">
            <section className="title__section">
              <Fade direction="up" cascade>
                <div className="title">
                  <p className="header__excerpt">INTRODUCING FEELER</p>
                  <h1>FEELER 본사를 소개합니다.</h1>
                </div>
              </Fade>

              <div className="img__container">
                <StaticImage
                  className="title__logo image"
                  src="../../images/feeler/ffglogo.gif"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              </div>
            </section>
          </Fade>

          <section className="desc__section">
            <div className="desc1 desc">
              <Fade delay="10">
                <div className="desc1__text desc__text">
                  <p className="header__excerpt">FAIR FRIEND GROUP</p>
                  <h1 className="desc1__header desc__header">
                    <span>FEELER </span>FOUNDATION
                  </h1>

                  <div className="content1 content">
                    <div className="content__text">
                      <Fade
                        direction="right"
                        duration="400"
                        className="z__index__fix"
                      >
                        <div className="content__h2">
                          <h2>HISTORY OF FAIR FRIEND GROUP</h2>
                        </div>
                      </Fade>
                      <Fade direction="right">
                        <div className="content__p">
                          <p>
                            Fair Friend Enterprise co.,Ltd 는 일본철강 및
                            기계제작회사의 대리점으로 1979년에 설립되었습니다.
                            설립이후 회사는 꾸준히 성장해 왔으며, 국내 시장에서
                            국제시장으로 끊임없이 확장시켜 현재 세계화 기업의
                            목표에 성취하였습니다.
                          </p>
                        </div>
                      </Fade>
                    </div>

                    <StaticImage
                      className="desc1__image image desc__image"
                      src="../../images/feeler/feelerbackground2.png"
                      alt="edm desc1 pic"
                      layout="fullWidth"
                      placeholder="blurred"
                    />
                  </div>
                </div>
              </Fade>
            </div>
            <div className="desc2 desc">
              <Fade delay="10">
                <div className="desc2__text desc__text">
                  <p className="header__excerpt">WHAT THEY DO</p>
                  <h1 className="desc2__header desc__header">
                    <span>FEELER </span>INDUSTRY
                  </h1>

                  <div className="content2 content">
                    <Fade
                      direction="left"
                      duration="400"
                      className="z__index__fix"
                    >
                      <div className="content__text">
                        <div className="content__h2">
                          <h2>MACHINING CENTER PRODUCTIOM </h2>
                        </div>
                        <Fade cascade duration="300" direction="up">
                          <ul className="content__ul">
                            {feelerProduction.map((item, i) => (
                              <li key={i}>
                                <p>
                                  <span>- </span>
                                  {item.text}
                                </p>
                              </li>
                            ))}
                            <p>
                              생산공장은 현재 대만, 홍콩, 중국, 일본, 미국
                              등지에 있으며, 사업범위는 각종공작기계,
                              기계/공압공구들, 장비, 건설기계, PCB, 모니터,
                              forklift 트럭등에 다양한 기계산업분야에
                              종사하고있습니다.
                            </p>
                          </ul>
                        </Fade>
                      </div>
                    </Fade>

                    <StaticImage
                      className="desc2__image image desc__image"
                      src="../../images/feeler/feelerService.jpg"
                      alt="edm desc2 pic"
                      layout="fullWidth"
                      placeholder="blurred"
                    />
                  </div>
                </div>
              </Fade>
            </div>
            <div className="desc3 desc">
              <Fade delay="10">
                <div className="desc3__text desc__text">
                  <h1 className="desc3__header desc__header">
                    <span>FEELER'S TODAY </span>
                    <br />
                    WHY FEELER?
                  </h1>

                  <div className="content3 content">
                    <div className="content__text">
                      <Fade
                        direction="right"
                        duration="400"
                        className="z__index__fix"
                      >
                        <div className="content__h2">
                          <h2>GLOBAL PIONEER IN MACHINING CENTER INDUSTRY</h2>
                        </div>
                      </Fade>
                      <Fade direction="right">
                        <div className="content__p">
                          <p>
                            Fiar Friend Group 의 신조는 성실, 품질,
                            고객우선주의, 지속적성장이며 현재 연구 개발은
                            대만에서, 제품생산은 중국에서 하고 있으며, 전세계로
                            마케팅을 확장하고 있는 다국적 기업으로 전 세계시장을
                            대상으로 공작기계메이커의 선두주자로써 프론티어
                            정신으로 약진중인 중견기업입니다.
                          </p>
                        </div>
                      </Fade>
                    </div>
                    <StaticImage
                      className="desc3__image image desc__image"
                      src="../../images/feeler/lathesCover2.png"
                      alt="edm desc3 pic"
                      layout="fullWidth"
                      placeholder="blurred"
                    />
                  </div>
                </div>
              </Fade>
            </div>
          </section>
        </div>
      </FeelerIntroStyles>
      <PageLinks brand="feeler" />
    </>
  )
}

export default FeelerIntro
