import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import { StaticImage } from "gatsby-plugin-image"
import Features from "../components/Features/Features"
import FeelerIntro from "../components/FeelerIntro/FeelerIntro"

const edm = () => {
  return (
    <>
      <Seo title="절삭가공" />
      <Layout>
        <SimpleBanner title="절삭가공">
          <StaticImage
            className="banner__image"
            src="../images/feeler/lathesCover.png"
            alt="lathes banner pic"
            layout="constrained"
            placeholder="blurred"
          />
        </SimpleBanner>
        <FeelerIntro />
        <Features />
      </Layout>
    </>
  )
}

export default edm
